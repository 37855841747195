import React, { useState, useEffect  } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box ,
} from "@mui/material";

import { getCCMDashboardData } from "../../api/index.js";

const CCMDashboard = () => {
  const [nbfcId, setNbfcId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [download, setDownload] = useState(false);
  const [applicationIds, setApplicationIds] = useState("");
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);

const LoadingMessages = () => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    
    const loadingMessages = [
      "Getting Nach Data of all due applications",
      "Getting Collection History of all due applications",
      "Getting Settlement Data of all due applications",
      "Getting Loan Details of all due applications",
      "Getting Collection Details of all due applications",
      "Getting Nach Success Percentage of all due applications",
      "Getting Communication Details of all due applications"
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => 
          prevIndex === loadingMessages.length - 1 ? 0 : prevIndex + 1
        );
      }, 2000); // Change message every 2 seconds

      return () => clearInterval(interval);
    }, []);

    return (
      <Grid item xs={12} sx={{ textAlign: 'center', py: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CircularProgress sx={{ color: 'rgb(110, 54, 255)' }} />
          <Typography 
            sx={{ 
              color: 'rgb(110, 54, 255)',
              minHeight: '24px', // Prevent layout shift
              transition: 'opacity 0.5s',
              animation: 'fadeInOut 2s infinite'
            }}
          >
            {loadingMessages[currentMessageIndex]}
          </Typography>
        </Box>
        <style>
          {`
            @keyframes fadeInOut {
              0% { opacity: 0.3; }
              50% { opacity: 1; }
              100% { opacity: 0.3; }
            }
          `}
        </style>
      </Grid>
    );
  };

  const SummaryTable = ({ data }) => {
    if (!data?.summary || !Array.isArray(data.summary)) return null;
  
    const [valuesRow, percentagesRow] = data.summary;
  
    return (
      <TableContainer 
        component={Paper} 
        sx={{ 
          maxWidth: 800,
          marginY: '20px',
          marginLeft: 0,
          marginRight: 'auto',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Table aria-label="summary table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'rgb(110, 54, 255)' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '50%' }}>
                Parameter
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '25%' }}>
                Value
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', width: '25%' }}>
                Percentage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(valuesRow).map((key) => (
              <TableRow
                key={key}
                sx={{ 
                  '&:nth-of-type(odd)': { backgroundColor: 'rgba(110, 54, 255, 0.05)' },
                  '&:hover': { backgroundColor: 'rgba(110, 54, 255, 0.1)' }
                }}
              >
                <TableCell 
                  component="th" 
                  scope="row"
                  sx={{ 
                    fontWeight: 'medium',
                    color: 'rgb(60, 60, 60)'
                  }}
                >
                  {key}
                </TableCell>
                <TableCell
                  sx={{ 
                    color: 'rgb(90, 90, 90)'
                  }}
                >
                  {valuesRow[key]}
                </TableCell>
                <TableCell
                  sx={{ 
                    color: 'rgb(90, 90, 90)'
                  }}
                >
                  {percentagesRow[key]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = applicationIds 
        ? {
            nbfc_id: nbfcId,
            due_date: dueDate,
            end_date: endDate,
            download: download,
            application_list: applicationIds
              .split(",")
              .map((id) => id.trim()),
          } 
        : {
            nbfc_id: nbfcId,
            due_date: dueDate,
            end_date: endDate,
            download: download,
          };
          const response = await getCCMDashboardData(payload);
          setSummaryData(response?.data);
      if (download) {
        const base64Data = response.data.base64Data;

        // const binaryData = atob(base64Data);
        if (!base64Data) {
          console.log('No file data received from the server');
          throw new Error("No file data received from the server");
        }
        const binaryData = atob(base64Data);

        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i);
        }
        const fileBlob = new Blob([arrayBuffer], { type: 'data:@file/xlsx;base64' });
    
        const downloadLink = document.createElement('a');
        const fileURL = URL.createObjectURL(fileBlob);
        downloadLink.href = fileURL;
        console.log('fileURL:', fileURL);
        downloadLink.download = `complete_collection_report_${nbfcId}_for_${dueDate}_till_${endDate}_time_${new Date().getTime()}.xlsx`;
        console.log('downloadLink:', downloadLink);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(fileURL);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      // Add user-friendly error message
      alert('Error downloading file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="nbfc-label">NBFC</InputLabel>
          <Select
            labelId="nbfc-label"
            value={nbfcId}
            label="NBFC"
            onChange={(event) => {
              setNbfcId(event.target.value);
            }}
            sx={{
              color: "rgb(110, 54, 255)",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(110, 54, 255)",
                border: 1,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(110, 54, 255)",
              },
              ".MuiSvgIcon-root ": {
                fill: "rgb(110, 54, 255)",
              },
            }}
          >
            <MenuItem value={"FI00005"}>Fintree</MenuItem>
            <MenuItem value={"MF00006"}>Mufin</MenuItem>
            <MenuItem value={"VA00001"}>Vani</MenuItem>
            <MenuItem value={"NY00002"}>NY</MenuItem>
            <MenuItem value={"NAM0007"}>Namdev</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          fullWidth
          label="Due Date"
          type="date"
          value={dueDate}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "rgb(110, 54, 255)",
              "& fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&:hover fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
            },
          }}
          onChange={(e) => {
            setDueDate(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          fullWidth
          label="End Date"
          type="date"
          value={endDate}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "rgb(110, 54, 255)",
              "& fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&:hover fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
            },
          }}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Download Report</FormLabel>
          <RadioGroup
            row
            value={download}
            onChange={(e) => setDownload(e.target.value === "true")}
          >
            <FormControlLabel
              value={true}
              control={<Radio sx={{ color: "rgb(110, 54, 255)" }} />}
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={<Radio sx={{ color: "rgb(110, 54, 255)" }} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Application IDs (Optional)"
          placeholder="EMAPL01042400004030, EMAPL01042400004035"
          value={applicationIds}
          onChange={(e) => setApplicationIds(e.target.value)}
          variant="outlined"
          size="small"
          multiline
          rows={2}
          helperText="Enter application IDs separated by commas"
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "rgb(110, 54, 255)",
              "& fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&:hover fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgb(110, 54, 255)",
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "rgb(110, 54, 255)",
            "&:hover": {
              backgroundColor: "rgb(90, 34, 235)",
            },
          }}
        >
          Generate Report
        </Button>
      </Grid>
      {loading && <LoadingMessages />}
      {summaryData && (
        <Grid item xs={12}>
          <SummaryTable data={summaryData} />
        </Grid>
      )}
    </Grid>
  );
};

export default CCMDashboard;