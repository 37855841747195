import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { statgeMap } from "../../../utility/Stages";

import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CachedIcon from "@mui/icons-material/Cached";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NearMeIcon from "@mui/icons-material/NearMe";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { isAfter } from "date-fns";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkNachStatus,
  editTransactionV2,
  fetchCollectionOverview,
  fetchGpsTrackingLink,
  fetchSchedule,
  fetchTransactionHistoryV2,
  fetchWACountCollection,
  getCollectionHistoryV2,
  getCollectionsDPD,
  getNachPresentData
} from "../../../api";
import CommentsModal from "../../../Components/Comments/Comments";
import CommunicationHistory from "../../../Components/Dialogs/CommunicationHistory";
import CollectionScheduleExport from "../../../Components/Download/CollectionScheduleExport";
import TransactionScheduleExport from "../../../Components/Download/TransactionHistoryExport";
import LoanClosure from "../../../Components/LoanClosure";
import NachPresent from "../../../Components/NachPresent";
import NachStatusUpdate from "../../../Components/NachStatusUpdate";
import OutstandingAmount from "../../../Components/OutstandingAmount";
import MouseOverPopover from "../../../Components/Popover";
import { GlobalContext } from "../../../Context/GlobalContext";
import { UserContext } from "../../../Context/UserContext";
import { f } from "../../../utility/Formater";
import generateTablePDF from "../../../utility/pdfGenerate";
import AdvancedCollectionView from "./AdvancedCollectionView";
import ScheduleViewer from "./ScheduleViewer";
import SimpleCollectionView from "./SimpleCollectionView";
import TransationHistoryV2 from "./TransactionHistoryV2";
import UpdateCollectionV2 from "./UpdateCollectionV2";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: 0,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 1,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
}));

export const NachPresentFailure = ({ noAmount, nachFailureDetails }) => {
  return (
    <div style={{ width: 300 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} className="fw-semibold font-monospace">
          Status
        </Grid>
        <Grid item xs={12} sm={9}>
          {nachFailureDetails?.status}
        </Grid>
        {!noAmount && (
          <Grid item xs={12} sm={3} className="fw-semibold font-monospace">
            Amount
          </Grid>
        )}
        {!noAmount && (
          <Grid item xs={12} sm={9}>
            {nachFailureDetails?.amount}
          </Grid>
        )}
        <Grid item xs={12} sm={3} className="fw-semibold font-monospace">
          Reason
        </Grid>
        <Grid item xs={12} sm={9}>
          {nachFailureDetails?.failure_reason}
        </Grid>
      </Grid>
    </div>
  );
};
// nach_checks.digio_acc_no = updateDetails?.digio_nach_data?.mandate_details?.customer_account_number?.slice(-4);
//     nach_checks.acc_no = updateDetails?.account_number?.slice(-4);
//     nach_checks.digio_ifsc = updateDetails?.digio_nach_data?.mandate_details?.destination_bank_id;
//     nach_checks.ifsc = updateDetails?.ifsc_code;
//     nach_checks.nach_status = updateDetails?.digio_nach_data?.bank_details?.state;

const NachPresentChecks = ({ nachChecks }) => {
  const fcd = nachChecks?.first_collection_date;
  return (
    <div style={{ width: 300 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="font-monospace">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span className="fw-semibold">Mandate Status</span>
            <span>{f(nachChecks?.nach_status)}</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="font-monospace">
          <div className="d-flex flex-column justify-content-center">
            <span className="text-primary" style={{ fontSize: "10px" }}>
              Bank Acc Details
            </span>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="fw-semibold">Digio</span>
              <span>{f(nachChecks?.digio_acc_no)}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="fw-semibold">ET</span>
              <span>{f(nachChecks?.acc_no)}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="font-monospace">
          <div className="d-flex flex-column justify-content-center">
            <span className="text-primary" style={{ fontSize: "10px" }}>
              Bank IFSC Details
            </span>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="fw-semibold">Digio</span>
              <span>{f(nachChecks?.digio_ifsc)}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span className="fw-semibold">ET</span>
              <span>{f(nachChecks?.ifsc)}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="font-monospace">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span className="fw-semibold" style={{ fontSize: "12px" }}>
              First Collection Date
            </span>
            <span>
              {fcd ? moment(fcd, "YYYY-MM-DD").format("DD-MM-YYYY") : f(null)}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const initialExpandData = {
  isPayment: false,
  array: [],
};

export const nachPresentColors = {
  active: "#00F",
  cancelled: "#FF0500",
  paused: "#C7A700",
  completed: "#10B800",
  scheduled: "#00F",
  failed: "#FF0500",
  success: "#10B800",
  [undefined]: "#71717A",
};

const ViewCollectionsV2 = ({
  applicationId,
  payment_basis,
  isDisbursed,
  nachStatus,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { updateDetailState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [collectionUpdate, setCollectionUpdate] = useState({
    open: false,
    is_colletion: false,
    payment_amount: "",
    transaction_date: moment().format("DD/MM/YYYY"),
    payment_mode: "Cash",
  });
  const [isLoader, setLoader] = useState(false);
  // const [digioNachStatus, setDigioNachStatus] = useState(`${nachStatus}`);
  const [digioNachStatus, setDigioNachStatus] = useState(nachStatus);
  const [isOverLoading, setOverLoading] = useState(false);
  const [simpleView, setSimpleView] = useState(true);
  const [value, setValue] = useState(0);
  const [collectionDetails, setCollectionDetails] = useState({});
  const [collectionData, setCollectionData] = useState([]);
  const [dpdData, setDpdData] = useState([]);
  const [dpdLoading, setDpdLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [loanSchedule, setSchedule] = useState({});
  const [isNewSchedule, setNewSchedule] = useState(false);
  const [nachUpdate, setNachUpdate] = useState({
    open: false,
  });

  const [showCollectionHistory, setShowCollectionHistory] = useState(false);
  const [collectionVersion, setCollectionVersion] = useState(1);
  const [collectionScheduleType, setCollectionSchemeType] = useState("emi");
  const [collectionDefaultScheduleType, setCollectionDefaultSchemeType] =
    useState("emi");

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [openOutstandAmount, setOpenOutstandAmount] = useState(false);
  const [openLoanClosure, setOpenLoanClosure] = useState(false);
  const [openComments, setOpenComments] = useState({
    open: false,
    applicationData: {},
  });
  const [isNachPresentRefreshed, setIsNachPresentRefreshed] = useState(false);
  const [openNachPresent, setOpenNachPresent] = useState(false);
  const [latestNachPresentData, setLatestNachPresentData] = useState({});
  const [gpsTrackingLink, setGpsTrackingLink] = useState(null);
  const [collectedHoldBackLeftToSettle, setCollectedHoldBackLeftToSettle] =
    useState(null);
  const [commentData, setCommentData] = useState([]);
  const [nachPresentChecks, setNachPresentChecks] = useState({});

  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [scroll, setScroll] = useState("Bottom");
  const [Communication, setCommunication] = useState({
    messageHistory: [],
    callHistory: [],
    load: false,
  });
  const [communicationDialog, setCommunicationDialog] = useState(false);
  const [communicationData, setCommunicationData] = useState({
    tab: 0,
    callingData: {},
    chatData: {},
  });
  const [countData, setCountData] = useState({
    sent_call_count: 0,
    not_answered: 0,
    sent_count: 0,
    recieve_count: 0,
    total_call_count: 0,
  });

  const [tab, setTab] = useState(0);
  const [customerData, setCustomerData] = useState({
    name: "",
    mobile_number: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorEl(null);
  };

  const getCollectionData = async (type) => {
    setLoader(true);
    try {
      let { data } = await getCollectionHistoryV2(applicationId, type);
      setCollectionData(data || []);
    } catch (error) {
      if (value === 1) {
        toast.info(error?.response?.data?.message);
      }
    }
    setLoader(false);
  };

  const getTransactionHistory = async () => {
    try {
      setLoader(true);
      let { data } = await fetchTransactionHistoryV2(applicationId);
      setTransactionData(data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoader(false);
  };

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  const [alignment, setAlignment] = React.useState("left");
  const [devices, setDevices] = React.useState(() => ["phone"]);
  const [expandData, setExpandData] = useState([]);

  const handleChangeType = (e) => {
    if (e.target.value !== null) {
      setCollectionSchemeType(e.target.value);
      setExpandedRows([]);
      setExpandState({});
      if (showCollectionHistory) {
        getCollectionData(e.target.value.toUpperCase());
      }
    }
  };

  const handleDevices = (event, newDevices) => {
    if (newDevices.length) {
      setDevices(newDevices);
    }
  };
  const handleNachClick = async () => {
    try {
      const response = await checkNachStatus({ application_id: applicationId });
      const { state = null } = response?.data;
      if (state) {
        setDigioNachStatus(state);
      }
      toast.info(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Server Error");
    }
  };

  const handleUpdateCollection = async (data) => {
    try {
      const { status } = await editTransactionV2(data, applicationId);
      if (status) {
        toast.success("Collection updated successfully");
        getCollectionData("EMI");
        getTransactionHistory();
      }
      setCollectionUpdate({
        open: false,
        is_colletion: false,
        payment_amount: "",
        transaction_date: moment().format("DD/MM/YYYY"),
        payment_mode: "Cash",
      });
      getCollectionDetails();
    } catch (err) {}
  };

  const findCollectionVersion = () => {
    if (
      isAfter(
        new Date(updateDetails?.disbursal_date_og),
        new Date(`2023/05/01`)
      )
    ) {
      setCollectionVersion(2);
      getCollectionData(payment_basis === "monthly" ? "EMI" : "EFI");
    } else {
      setCollectionVersion(1);
      getCollectionData("EMI");
    }
  };

  const fetchCommunicationHistory = async () => {
    try {
      const { data } = await fetchWACountCollection();
      setCommunication({
        messageHistory: data?.data || {},
        callHistory: data?.call_data || {},
        load: true,
      });
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  };

  const style = {
    color: "white",
    fontSize: 10,
    borderRadius: 30,
    padding: "2px 5px",
  };
  useEffect(() => {
    // getCollectionData("EMI");
    getCollectionDetails();
    getSchedule();
    getGpsTrackingLink();
    // isNachPresentDisabled();
  }, [applicationId]);

  useEffect(() => {
    if (isDisbursed) {
      setShowCollectionHistory(isDisbursed);
      setValue(1);
    }
  }, [isDisbursed]);

  useEffect(() => {
    if (collectionData?.length > 0) {
      let arr = [];
      collectionData?.forEach((item) => {
        arr.push({
          isPayment: null,
          array: [],
        });
      });
      setExpandData([...arr]);
    }
  }, [collectionData]);

  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop >
      event.currentTarget.offsetHeight - event.currentTarget.offsetHeight / 2
    ) {
      setScroll("Top");
    }
    if (event.currentTarget.scrollTop < event.currentTarget.scrollHeight / 2) {
      setScroll("Bottom");
    }
  };

  const getGpsTrackingLink = async () => {
    try {
      const { data: response } = await fetchGpsTrackingLink(applicationId);
      setGpsTrackingLink(response?.link);
    } catch (err) {
      setGpsTrackingLink(null);
    }
  };

  const getCollectionDetails = async () => {
    try {
      setOverLoading(true);
      let { data: response } = await fetchCollectionOverview(applicationId);
      const { data } = response;
      setCollectionDetails({
        nextDueAmount: data?.collectionAsOnDate,
        nextDueDate: data?.dueDate
          ? moment(new Date(data?.dueDate)).format("DD/MM/YYYY")
          : "NA",
        totalCollected: data?.totalCollected,
      });
      setCommentData(data?.collectionData?.comments || []);
      setCustomerData({
        mobile_number: data?.customer_mobile_number,
        name: data?.customer_name,
      });
      setCollectedHoldBackLeftToSettle(data?.collectedHoldBackLeftToSettle);
    } catch (error) {
      setCollectionDetails({
        totalCollected: "",
        nextDueAmount: "",
      });
    }
    setOverLoading(false);
  };
  // applicationData?.customerData?.pan_details?.name ||
  // applicationData?.customerData?.corporate_pan_details?.name ||
  function handleOpenComments() {
    setOpenComments({
      open: true,
      applicationData: {
        application_id: updateDetails?.application_id,
        customerData: updateDetails?.customer_name,
      },
    });
  }

  function handleCloseView() {
    setOpenComments({
      open: false,
      applicationData: {},
    });
  }

  async function handleDpdSubmit(minimum_due_amount) {
    setDpdLoading(true);
    try {
      const { data: dpdData } = await getCollectionsDPD({
        applicationId,
        minimum_due_amount,
      });
      setDpdData(dpdData?.dpd);
    } catch (error) {
      toast.error(error?.message);
    }
    setDpdLoading(false);
  }

  const getSchedule = async (id) => {
    try {
      setLoader(true);
      let { data } = await fetchSchedule(applicationId);
      setSchedule(data?.data);
      setNewSchedule(data?.new_schedule || false);
    } catch (error) {}

    setLoader(false);
  };

  const handleDownload = (e) => {
    // Headers for each column
    let headers =
      value === 1
        ? [
            `SL NO,Installment Amount,Due Date,Payment Date,Status,Paid via, Transaction Id, UTR, DPD`,
          ]
        : [
            `SL NO,Transaction Amount,Transaction Date,Payment Mode, Transaction UTR, Transaction Number`,
          ];

    // Convert users data to a csv
    let count = 1;
    let usersCsv = (
      filteredData?.length > 0
        ? filteredData
        : value === 1
        ? collectionData
        : transactionData
    ).reduce((acc, row, index) => {
      acc.push(
        value === 1
          ? [
              row["installment_number"],
              row["installment_amount"],
              moment(row?.due_date).format("DD/MM/YYYY"),
              row?.settlement_data?.length == 0 || row?.settlement_data == null
                ? "-"
                : moment(
                    new Date(
                      row?.settlement_data[
                        row?.settlement_data?.length - 1
                      ]?.payment_date
                    )
                  ).format("DD/MM/YYYY"),
              statgeMap.COL_STATUS?.[row?.current_status] !== "NOT_UPDATED"
                ? statgeMap.COL_STATUS?.[row?.current_status]
                : "-",
              row?.settlement_data?.slice(-1)[0]?.payment_mode,
              row?.settlement_data?.slice(-1)[0]?.transaction_id,
              row?.settlement_data?.slice(-1)[0]?.transaction_utr,
              dpdData[index],
            ].join(",")
          : [
              count++,
              row?.transaction_amount,
              moment(row?.transaction_dateMS).format("DD/MM/YYYY"),
              row?.payment_mode,
              row?.transaction_utr,
              row?.transaction_id,
            ]
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName:
        value === 1
          ? `Collection Schedule - ${applicationId}`
          : `Transaction History - ${applicationId}`,
      fileType: "text/csv",
    });
  };

  const downloadSchedule = () => {
    let headers = [
      `"SL NO","Due Date","${collectionScheduleType}","Principal","Interest","O/s Principal"`,
    ];

    let data = {
      repayment_amount: 0,
      interest_amount: 0,
    };

    // Convert users data to a csv
    let usersCsv = loanSchedule?.[collectionScheduleType + "Sheet"].reduce(
      (acc, row) => {
        data.repayment_amount += Number(
          row[collectionScheduleType.toUpperCase()]
        );
        data.interest_amount += Number(row["Interest"]);

        acc.push(
          [
            row["SL_NO"],
            row["Due_Date"],
            row[collectionScheduleType.toUpperCase()],
            row["Principal"],
            row["Interest"],
            row["O/s_Principal"],
          ].join(",")
        );
        return acc;
      },
      []
    );

    let info = [
      `Customer Name,${updateDetails?.customer_name}`,
      `Application ID,${applicationId}`,
      `Loan Amount,${updateDetails?.loan_amount}`,
      `ROI,${updateDetails?.interest_rate}%`,
      `Tenure,${updateDetails?.tenure_value}`,
      `Interest,${(data?.interest_amount).toFixed(2)}`,
      `Repayment,${(data?.repayment_amount).toFixed(2)}`,
      `Disbursal Date,${updateDetails?.disbursal_date}`,
      ``,
      ``,
    ];

    downloadFile({
      data: [...info, ...headers, ...usersCsv].join("\n"),
      fileName: `${applicationId}-Schedule(${collectionScheduleType})`,
      fileType: "text/csv",
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handlePdfDownload = (e) => {
    let data = {
      repayment_amount: 0,
      interest_amount: 0,
    };

    loanSchedule?.[collectionScheduleType + "Sheet"].forEach((val) => {
      data.repayment_amount += Number(
        val[collectionScheduleType.toUpperCase()]
      );
      data.interest_amount += Number(val["Interest"]);
    });

    generateTablePDF(
      loanSchedule?.[collectionScheduleType + "Sheet"],
      {
        applicationId,
        name: updateDetails?.customer_name,
        nbfc: updateDetails?.nbfc_name,
        loanAmount: updateDetails?.loan_amount + "",
        interest: updateDetails?.interest_rate,
        interest_amount: data?.interest_amount + "",
        repayment_amount: data?.repayment_amount + "",
        tenure: updateDetails?.tenure_value + "",
        disbursalDate: updateDetails?.disbursal_date,
      },
      `${applicationId}-Schedule(${collectionScheduleType}).pdf`,
      collectionScheduleType
    );
    handleCloseDownload();
  };

  async function handleRefeshNachPresent() {
    try {
      setIsNachPresentRefreshed(true);
      let params = `${updateDetails?.application_id}/${updateDetails?.nbfc_id}/${latestNachPresentData?.id}`;
      const { data } = await getNachPresentData(params);

      setLatestNachPresentData(data?.data);
      toast(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "refresh failed !!!");
    } finally {
      setIsNachPresentRefreshed(false);
    }
  }

  let isNachPresentDisabled = () => {
    let disabled = false;
    let nach_checks = {};
    nach_checks.digio_acc_no =
      updateDetails?.digio_nach_data?.latest?.mandate_details?.customer_account_number?.slice(
        -4
      );
    nach_checks.acc_no = updateDetails?.account_number?.slice(-4);
    nach_checks.digio_ifsc =
      updateDetails?.digio_nach_data?.latest?.mandate_details?.destination_bank_id;
    nach_checks.ifsc = updateDetails?.ifsc_code;
    nach_checks.nach_status =
      updateDetails?.digio_nach_data?.latest?.bank_details?.state;
    nach_checks.first_collection_date =
      updateDetails?.digio_nach_data?.latest?.mandate_details?.first_collection_date;

    if (nach_checks.digio_acc_no !== nach_checks.acc_no) {
      disabled = true;
    }

    if (nach_checks.digio_ifsc !== nach_checks.ifsc) {
      disabled = true;
    }

    if (nach_checks.nach_status !== "register_success") {
      disabled = true;
    }

    nach_checks.disabled = disabled;

    const redirect = queryParams.get("redirect");
    if (redirect === "present") {
      if (!disabled) {
        setOpenNachPresent(true);
      } else {
        toast.warn("Nach present not available for this application yet");
      }
    }

    setNachPresentChecks({ ...nach_checks });
  };

  function getLatestNachForThisMonthToPrev(nachPresents=[]){
    let currMonth = new Date().getMonth();
    let currYear = new Date().getFullYear();

    let latestNach = null;
    let latestYear = -Infinity;
    let latestMonth = -1;

    for(let nach_data of nachPresents ){
      if(nach_data?.nach_txn_details?.scheduled_settlement_date){
        let dateObj = moment(nach_data?.nach_txn_details?.scheduled_settlement_date);
        let year = dateObj?.year();
        let month = dateObj?.month();

        if(year <=currYear && (year < currYear || month <= currMonth)) {
                if (
                    year > latestYear || 
                    (year === latestYear && month > latestMonth)
                ) {
                    latestYear = year;
                    latestMonth = month;
                    latestNach = nach_data;
                  }
        }
      }
    }

    return latestNach;
  }

  useEffect(() => {
    setDigioNachStatus(nachStatus);
  }, [nachStatus]);

  // useEffect(() => {
  //   findCollectionVersion();
  // }, [updateDetails]);

  useEffect(() => {
    if (updateDetails?.application_id) {
      const nach_present = getLatestNachForThisMonthToPrev(updateDetails?.collectionData?.nach_presentation_data || []);
      
      findCollectionVersion();
      setLatestNachPresentData(nach_present);
      isNachPresentDisabled();
    }
  }, [updateDetails?.application_id]);

  useEffect(() => {
    if (collectionVersion === 1) {
      setCollectionSchemeType("emi");
    } else {
      setCollectionSchemeType(payment_basis === "monthly" ? "emi" : "efi");
    }
  }, [collectionVersion]);

  useEffect(() => {
    if (value === 0) {
      setCollectionSchemeType("emi");
    }
    if (value === 2) {
      getTransactionHistory();
    }
  }, [value]);

  useEffect(() => {
    fetchCommunicationHistory();
  }, []);

  useEffect(() => {
    ///////////Assignment for calling History and message history///////////////////
    if (Communication?.load) {
      const cust_mobile_number = customerData?.mobile_number;

      const sent_call_count =
        Communication.callHistory?.[cust_mobile_number]?.completed_call_count ||
        0;
      const not_answered =
        Communication.callHistory?.[cust_mobile_number]?.no_answer_call_count ||
        0;
      const total_call_count = sent_call_count + not_answered;

      const sent_count =
        Communication.messageHistory?.[cust_mobile_number]?.sent_count || 0;
      const receive_count =
        Communication.messageHistory?.[cust_mobile_number]?.receive_count || 0;

      setCountData({
        sent_call_count: sent_call_count,
        not_answered: not_answered,
        sent_count: sent_count,
        recieve_count: receive_count,
        total_call_count: total_call_count,
      });
    }
  }, [Communication]);

  return (
    <>
      {collectionData?.length > 0 && (
        <div style={{ marginTop: "-25px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p
                  style={{ color: "#716E6E", marginBottom: 0, fontWeight: 400 }}
                >
                  Overview
                </p>
                {[1,5].includes(Number(user?.role)) && updateDetails?.is_disbursed && (
                  <Grid item xs={2} sm={2} md={2}>
                    <div
                    // className="over-view-card"
                    // style={{
                    //   height: "20px",
                    // }}
                    >
                      <div className="d-flex flex-row align-items-center">
                        {updateDetails?.current_status === "closed" && (
                          <span
                            className="mx-3"
                            style={{
                              fontSize: 14,
                            }}
                          >
                            Closure Date :{" "}
                            {updateDetails?.closure_details?.settlementDateMs
                              ? moment(
                                  updateDetails?.closure_details
                                    ?.settlementDateMs
                                ).format("DD/MM/yyyy")
                              : "NA"}
                          </span>
                        )}
                        <div>
                          {updateDetails?.current_status === "closed" ? (
                            <button
                              style={{
                                color: "rgba(23, 94, 232, 1)",
                                outline: "none",
                                border: "none",
                                background: "none",
                              }}
                              // className="amount"
                              onClick={() => {
                                setOpenLoanClosure(true);
                              }}
                            >
                              View Loan
                            </button>
                          ) : (
                            <button
                              style={{
                                color: "rgba(23, 94, 232, 1)",
                                outline: "none",
                                border: "none",
                                background: "none",
                                marginBottom: "0",
                                fontSize: "15px",
                                fontWeight: "500",
                                // marginBottom: "10px",
                              }}
                              className="amount"
                              onClick={() => {
                                setOpenLoanClosure(true);
                              }}
                            >
                              Close Loan
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} className="mb-3">
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Loan Amount</p>
                    <p className="amount">
                      {f(updateDetails?.loan_amount, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Repayment Amount</p>
                    <p className="amount">
                      {f(updateDetails?.repayment_amount, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Collected Amount</p>
                    <p className="amount">
                      {f(collectionDetails?.totalCollected, "cur")}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">Total Due till today</p>
                    <p className="amount">
                      {f(collectionDetails?.nextDueAmount, "cur")}
                    </p>
                  </div>
                </Grid>
                {/* {Number(user?.role) !== 3 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="over-view-card flex-column">
                      <p className="head">Nach Status</p>
                      {getNachStatusJsx(digioNachStatus)}
                      {digioNachStatus === "register_failed" && (
                        <Button
                          style={{ padding: 0 }}
                          onClick={() => {
                            setNachUpdate({
                              ...nachUpdate,
                              open: true,
                            });
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </Grid>
                )} */}
                {/* {Number(user?.role) !== 3 && (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="over-view-card">
                      <p className="head">GPS Status</p>
                      <p className="amount">
                        {updateDetails?.gps_unique_number ? (
                          <>
                            Installed{" "}
                            <Button
                              onClick={() => {
                                setOpenMMIdialog(true);
                              }}
                            >
                              edit
                            </Button>
                          </>
                        ) : (
                          <>
                            Not Installed{" "}
                            <Button
                              onClick={() => {
                                setOpenMMIdialog(true);
                              }}
                            >
                              Add
                            </Button>
                          </>
                        )}
                      </p>
                    </div>
                  </Grid>
                )} */}
                {[1, 2, 5].includes(Number(user?.role)) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="over-view-card">
                      <p className="head">Outstanding Amount</p>
                      <p className="amount">
                        <>
                          <Button
                            onClick={() => {
                              setOpenOutstandAmount(true);
                            }}
                            style={{
                              marginLeft: -13,
                              paddingLeft: "0 !important",
                            }}
                          >
                            View
                          </Button>
                        </>
                      </p>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <div className="over-view-card">
                    <p className="head">
                      <strong>Last Comment</strong>
                    </p>
                    <p className="head">
                      {commentData.length
                        ? commentData[commentData.length - 1].tag
                        : ""}
                    </p>
                    <p className="amount">
                      <>
                        <Button
                          onClick={() => {
                            handleOpenComments();
                          }}
                          style={{
                            marginLeft: -13,
                            paddingLeft: "0 !important",
                          }}
                        >
                          Show
                        </Button>
                      </>
                    </p>
                  </div>
                </Grid>
                {[1, 2, 5].includes(Number(user?.role)) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="over-view-card">
                      <p className="head">Message History</p>
                      <Button
                        sx={{ mx: 1 }}
                        onClick={() => {
                          setCommunicationData({
                            tab: 1,
                            callingData: {
                              open: true,
                              application_id: applicationId,
                              picked_call_count: countData?.sent_call_count,
                              declined_busy_count: countData?.not_answered,
                              total_count: countData?.total_call_count,
                            },
                            chatData: {
                              open: true,
                              mobile_number: customerData?.mobile_number,
                              customer_name: customerData.name || "",
                            },
                          });
                          setCommunicationDialog(true);
                          setTab(1);
                        }}
                      >
                        <>
                          <WhatsAppIcon fontSize="small" className="me-2" />
                          <span
                            style={{
                              ...style,
                              marginRight: "2px",
                              background:
                                countData?.sent_count === 0
                                  ? "#bdbdbd"
                                  : "green",
                            }}
                          >
                            <ArrowUpwardIcon sx={{ fontSize: 10 }} />
                            {countData?.sent_count}
                          </span>
                          <span
                            style={{
                              ...style,
                              background:
                                countData?.recieve_count === 0
                                  ? "#bdbdbd"
                                  : "green",
                            }}
                          >
                            <ArrowDownwardIcon sx={{ fontSize: 10 }} />
                            {countData?.recieve_count}
                          </span>
                        </>
                      </Button>
                    </div>
                  </Grid>
                )}
                {[1, 2, 5].includes(Number(user?.role)) && (
                  <Grid item xs={8} sm={6} md={3}>
                    <div className="over-view-card">
                      <p className="head">Calling history</p>
                      <Button
                        sx={{ mx: 1 }}
                        onClick={() => {
                          setCommunicationData({
                            tab: 2,
                            callingData: {
                              open: true,
                              application_id: applicationId,
                              picked_call_count: countData?.sent_call_count,
                              declined_busy_count: countData?.not_answered,
                              total_count: countData?.total_call_count,
                            },
                            chatData: {
                              open: true,
                              mobile_number: customerData.mobile_number,
                              customer_name: customerData.name || "",
                            },
                          });
                          setCommunicationDialog(true);
                          setTab(2);
                        }}
                      >
                        <PhoneForwardedOutlinedIcon fontSize="small" />
                        <span
                          style={{
                            ...style,
                            marginRight: "2px",
                            marginLeft: "5px",
                            background:
                              countData?.sent_call_count === 0
                                ? "#bdbdbd"
                                : "green",
                          }}
                        >
                          <ArrowUpwardIcon sx={{ fontSize: 10 }} />
                          {countData?.sent_call_count}
                        </span>
                        <span
                          style={{
                            ...style,
                            background:
                              countData?.not_answered === 0 ? "#bdbdbd" : "red",
                          }}
                        >
                          <ArrowDownwardIcon sx={{ fontSize: 10 }} />
                          {countData?.not_answered}
                        </span>
                      </Button>
                    </div>
                  </Grid>
                )}
                {([1, 5].includes(Number(user?.role)) || user?.email?.includes("offshoot")) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="over-view-card">
                      <Grid container>
                        <Grid xs={12} sm={7}>
                          <div className="head text-break text-wrap">
                            <span>Nach presentation</span>
                          </div>
                          <div className="amount text-wrap">
                            <span className="d-flex flex-row align-items-center">
                              <Button
                                onClick={() => {
                                  setOpenNachPresent(true);
                                }}
                                style={{
                                  marginLeft: -13,
                                  paddingLeft: "0 !important",
                                }}
                                disabled={nachPresentChecks?.disabled}
                              >
                                View
                              </Button>
                              {nachPresentChecks?.disabled && (
                                <MouseOverPopover
                                  renderUI={
                                    <NachPresentChecks
                                      nachChecks={nachPresentChecks}
                                    />
                                  }
                                  head={
                                    <InfoOutlinedIcon
                                      fontSize="small"
                                      color="error"
                                      sx={{ fontSize: 16 }}
                                    />
                                  }
                                />
                              )}
                            </span>
                          </div>
                        </Grid>
                        <Grid xs={12} sm={5}>
                          <div className="head text-break">
                            <span>Nach status</span>
                          </div>
                          <div
                            className="amount text-wrap text-uppercase d-flex flex-row align-items-center"
                            style={{ fontSize: "10px" }}
                          >
                            <span
                              style={{
                                color:
                                  nachPresentColors[
                                    latestNachPresentData?.nach_txn_details
                                      ?.state
                                  ],
                                textTransform: "capitalize",
                              }}
                            >
                              {latestNachPresentData?.nach_txn_details?.state ||
                                "NA"}
                            </span>
                            {Boolean(
                              latestNachPresentData?.nach_txn_details?.details
                                ?.failure_reason
                            ) && (
                              <MouseOverPopover
                                className="ms-1"
                                renderUI={
                                  <NachPresentFailure
                                    nachFailureDetails={
                                      latestNachPresentData?.nach_txn_details
                                        ?.details
                                    }
                                  />
                                }
                                head={
                                  <InfoOutlinedIcon
                                    fontSize="small"
                                    sx={{ fontSize: 16 }}
                                  />
                                }
                              />
                            )}
                            {Boolean(latestNachPresentData?.id) && (
                              <IconButton
                                className={
                                  isNachPresentRefreshed ? "rotate-class" : ""
                                }
                                size="small"
                                color="primary"
                                disabled={isNachPresentRefreshed}
                                onClick={handleRefeshNachPresent}
                              >
                                <CachedIcon sx={{ fontSize: "14px" }} />
                              </IconButton>
                            )}
                          </div>
                          <div
                            className="amount text-break text-wrap text-uppercase d-flex flex-column"
                            style={{ fontSize: "10px" }}
                          >
                            {Boolean(latestNachPresentData?.id) && (
                              <span>
                                {dayjs(
                                  latestNachPresentData?.nach_txn_details
                                    ?.scheduled_settlement_date
                                ).format("DD-MM-YYYY")}
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {value !== 2 && (
              <FormControl className="">
                <Stack direction="row" spacing={4} className="mb-2">
                  <ToggleButtonGroup
                    value={collectionScheduleType}
                    exclusive
                    onChange={handleChangeType}
                    aria-label="text alignment"
                    // disabled={collectionData?.length === 0}
                    className="toggle-btn-holder"
                    size="small"
                  >
                    <ToggleButton value="emi" className="toggle-btn">
                      EMI
                    </ToggleButton>
                    {collectionVersion === 2 && (
                      <ToggleButton
                        value={collectionVersion === 2 ? "efi" : "edi"}
                        className="toggle-btn"
                      >
                        {collectionVersion === 2 ? "EFI" : "EDI"}
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </Stack>
              </FormControl>
            )}
            <FormControl sx={{ ml: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                style={{ height: 30 }}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setShowCollectionHistory(e.target.value === 1 ? true : false);
                  setFilterApplied(false);
                  setFilteredData([]);
                  setOpenFilter(false);
                }}
              >
                <MenuItem value={0}>Schedule</MenuItem>
                <MenuItem value={1}>Collection History</MenuItem>
                <MenuItem value={2}>Transaction History</MenuItem>
              </Select>
            </FormControl>

            {filteredData.length <= 0 ? (
              <Button
                sx={{ color: "gray", fontSize: 12 }}
                onClick={() => {
                  setFilterApplied(true);
                  setOpenFilter(true);
                }}
              >
                <FilterAltIcon sx={{ fontSize: 20 }} /> filter
              </Button>
            ) : (
              <>
                <Button
                  sx={{ fontSize: 12 }}
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <FilterAltIcon sx={{ fontSize: 20 }} /> filters
                </Button>
                <span
                  className="pointer"
                  onClick={() => {
                    setFilterApplied(false);
                    setFilteredData([]);
                  }}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }} />
                </span>
              </>
            )}
          </div>

          <div>
            {value === 1 && (
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={simpleView}
                    onChange={(e) => {
                      setSimpleView(e.target.checked);
                    }}
                  />
                }
                label={
                  <span style={{ color: "gray", fontSize: 13 }}>
                    <RemoveRedEyeIcon sx={{ mr: 0.2 }} />
                    {simpleView ? "Simple" : "Advanced"}
                  </span>
                }
              />
            )}
            {collectionData?.length > 0 &&
              (value === 0 ? (
                <>
                  <>
                    <Button
                      id="basic-button"
                      color="inherit"
                      aria-controls={openMenu ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      onClick={handleClickDownload}
                    >
                      Export <DownloadIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseDownload}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={downloadSchedule}>CSV</MenuItem>
                      <MenuItem onClick={handlePdfDownload}>PDF</MenuItem>
                    </Menu>
                  </>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    {" "}
                    <FileDownloadIcon /> Export
                  </Button>
                </>
              ))}
            {value === 1 && isDisbursed && Number(user?.role) !== 3 && (
              <>
                <Button
                  onClick={() => {
                    navigate(
                      `/update-collection-v2/${updateDetails?.application_id}`,
                      {
                        state: {
                          path: `/view-application/${updateDetails?.application_id}/5`,
                        },
                      }
                    );
                  }}
                >
                  <AddIcon /> Add Transaction
                </Button>
              </>
            )}
            {isDisbursed && gpsTrackingLink != null && (
              <>
                <Button
                  sx={{
                    width: "75px",
                    height: "35px",
                    color: "white",
                    backgroundImage:
                      "linear-gradient(to right, #801AE6, #A21AE6)",
                  }}
                  onClick={() => {
                    window.open(gpsTrackingLink);
                  }}
                >
                  <NearMeIcon /> GPS
                </Button>
              </>
            )}
          </div>
        </div>

        {value === 1 &&
          (!simpleView ? (
            <AdvancedCollectionView
              collectionData={
                filteredData.length > 0 ? filteredData : collectionData
              }
              collectionScheduleType={collectionScheduleType}
              collectionDefaultScheduleType={collectionDefaultScheduleType}
              expandData={expandData}
              setExpandData={setExpandData}
              setCollectionUpdate={setCollectionUpdate}
              isLoader={isLoader}
              isDisbursed={isDisbursed}
              expandedRows={expandedRows}
              handleEpandRow={handleEpandRow}
            />
          ) : (
            <SimpleCollectionView
              collectionData={
                filteredData.length > 0 ? filteredData : collectionData
              }
              dpdData={dpdData}
              dpdLoading={dpdLoading}
              collectionScheduleType={collectionScheduleType}
              collectionDefaultScheduleType={collectionDefaultScheduleType}
              expandData={expandData}
              setExpandData={setExpandData}
              setCollectionUpdate={setCollectionUpdate}
              isLoader={isLoader}
              isDisbursed={isDisbursed}
              expandedRows={expandedRows}
              handleEpandRow={handleEpandRow}
              handleDpdSubmit={handleDpdSubmit}
            />
          ))}
      </div>
      {value === 0 && (
        <div>
          <ScheduleViewer
            applicationId={applicationId}
            scheduleType={collectionScheduleType}
            loanSchedule={loanSchedule}
            setSchedule={setSchedule}
          />
        </div>
      )}

      {value === 2 && (
        <div>
          <TransationHistoryV2
            applicationId={applicationId}
            transactionData={
              filteredData?.length > 0 ? filteredData : transactionData
            }
            setTransactionData={setTransactionData}
            customerName={updateDetails?.customer_name || "NO-NAME"}
            setCollectionUpdate={setCollectionUpdate}
            isLoader={isLoader}
          />
        </div>
      )}

      <UpdateCollectionV2
        applicationId={applicationId}
        open={collectionUpdate?.open}
        initalState={collectionUpdate}
        handleClose={(reload, isTransaction) => {
          setCollectionUpdate({
            open: false,
            is_colletion: false,
            payment_amount: "",
            transaction_date: moment().format("DD/MM/YYYY"),
            payment_mode: "Cash",
          });
          if (reload) {
            getCollectionData("EMI");
            if (isTransaction) {
              getTransactionHistory();
            }
          }
        }}
        onSubmit={async (data) => {
          await handleUpdateCollection(data);
        }}
        isTransactionHistory={value}
        collectedHoldBackLeftToSettle={collectedHoldBackLeftToSettle}
      />

      <TransactionScheduleExport
        applicationId={applicationId}
        open={openFilter && value === 2}
        collectionData={transactionData}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={async (data) => {
          setFilteredData(data);
        }}
      />

      <CollectionScheduleExport
        applicationId={applicationId}
        open={openFilter && value === 1}
        collectionData={collectionData}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={async (data) => {
          setFilteredData(data);
        }}
      />

      <NachStatusUpdate
        open={nachUpdate?.open}
        handleClose={() => {
          setNachUpdate({
            ...nachUpdate,
            open: false,
          });
        }}
        applicationId={applicationId}
      />

      <OutstandingAmount
        open={openOutstandAmount}
        handleClose={() => {
          setOpenOutstandAmount(false);
        }}
        applicationId={updateDetails?.application_id}
      />
      <LoanClosure
        open={openLoanClosure}
        handleClose={() => {
          setOpenLoanClosure(false);
        }}
        isClosed={updateDetails?.current_status === "closed"}
      />
      <CommentsModal
        openView={openComments?.open}
        applicationData={openComments?.applicationData}
        handleCloseView={handleCloseView}
        loadComments={() => {}}
        // handleTableUpdate={handleTableUpdate}
        defaultType={"COLLECTION"}
      />
      <NachPresent
        open={openNachPresent}
        handleClose={() => {
          setOpenNachPresent(false);
        }}
        latestNachPresentData={latestNachPresentData}
        setLatestNachPresentData={setLatestNachPresentData}
        nachPresentColors={nachPresentColors}
      />
      <CommunicationHistory
        modal={communicationDialog}
        handleClose={() => setCommunicationDialog(false)}
        tab={tab}
        changeTab={() => {
          setTab(tab === 1 ? 2 : 1);
        }}
        chatData={communicationData.chatData}
        callData={communicationData.callingData}
        commData={communicationData}
      />
    </>
  );
};

export default ViewCollectionsV2;
