import {useState, useEffect, useRef} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import loginBg from "../../assets/login-bg.jpg";
import Particles from "./Particles";
import useAuth from "../../hooks/useAuth";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function LoginV2({setEmail}) {
  const { loginUser } = useAuth();
  const passRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [cred, setCred] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    setLoading(true);
    try {
      await loginUser(cred.email, cred.password);
      setEmail(cred.email);
      localStorage.setItem('email', cred.email);
    } catch (error) {}
    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    // });
  };

  useEffect(() => {
    if (showPass) {
      passRef.current.type = "text";
    } else {
      passRef.current.type = "password";
    }
  }, [showPass]);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="login-screen-wrapper"
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            display: { xs: "none", md: "block",lg:"block" },
            position: "relative",
          }}
        >
          <div className="build-text">
            <div class="main">
              <h1>
                {/* Embifi! */}
                <div class="roller">
                  <span id="rolltext">
                    BUILD
                    <br />
                    ENABLE
                    <br />
                    EMBED
                    <br />
                  </span>
                </div>
              </h1>
            </div>
          </div>
          <Particles />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            // backgroundImage: `url(${loginBg})`,
            // backgroundRepeat: "no-repeat",
            // backgroundColor: (t) =>
            //   t.palette.mode === "light"
            //     ? t.palette.grey[50]
            //     : t.palette.grey[900],
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundColor: "red",
            position: "relative",
          }}
          className="login-card-holder"
        >
          <div className="login-card">
            <div className="container">
              <div className="form">
                <div className="title">Login</div>
                <div className="input-box underline">
                  <input
                    type="text"
                    autofocus
                    placeholder="Enter Your Email"
                    value={cred.email}
                    onChange={(e) => {
                      setCred({ ...cred, email: e.target.value });
                    }}
                  />
                  <div className="underline"></div>
                </div>
                <hr />
                <div
                  className="input-box"
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    ref={passRef}
                    type="password"
                    placeholder="Enter Your Password"
                    value={cred.password}
                    onChange={(e) => {
                      setCred({ ...cred, password: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleLogin();
                      }
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    {showPass ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                  </div>
                </div>
                <div className="input-box button">
                  {!isLoading ? (
                    <input
                      type="submit"
                      name=""
                      value="Continue"
                      onClick={handleLogin}
                    />
                  ) : (
                    <p
                      className="w-100 text-center"
                      style={{ color: "purple" }}
                    >
                      Signing in....
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
