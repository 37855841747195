import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CreateAgent from "./Pages/Agent/CreateAgentV2";
import CreateAnchorV2 from "./Pages/Anchor/CreateAnchorV2";
import CreateAnchor from "./Pages/CreateAnchor";
import CreateApplication from "./Pages/CreateSteps/CreateApplication";
import CreateUser from "./Pages/CreateUser";
import OemDetailsV2 from "./Pages/OemDetailsV2";
import Scheduler from "./Pages/Scheduler/V2/Scheduler";
import { useContext, useEffect, useState } from "react";
import Notifications from "./Components/Dialogs/Notifications";
import NotificationPopup from "./Components/NotificationPopup";
import { GlobalContext } from "./Context/GlobalContext";
import AddProduct from "./Pages/AddProduct/AddProducts";
import UpdateAnchor2 from "./Pages/Anchor/UpdateAnchor";
import "./Pages/Auth/Login.css";
import LoginV2 from "./Pages/Auth/LoginNew";
import ProtectedRoute from "./Pages/Auth/ProtectedRoute";
import PublicRoute from "./Pages/Auth/PublicRoute";
import B2CValidations from "./Pages/CRE/b2cValidation";
import AddTransacation from "./Pages/Collection/AddTransacation";
import CsvJson from "./Pages/CsvJson";
import { Dashboard } from "./Pages/Dashboard";
import DealerProfile from "./Pages/DealerProfile";
import { GPSOverview } from "./Pages/Demo/GpsOverview";
import DocumentUploader from "./Pages/DocumentUpload/DocumentUploader";
import ViewDocuments from "./Pages/DocumentUpload/ViewDocuments";
import DRFSheet from "./Pages/Features/DRFSheet";
import DealerScore from "./Pages/Features/DealerScore";
import KYCDashboard from "./Pages/KYC/KYCDashboard";
import SelectKYC from "./Pages/KYC/KYCList";
import AadhaarOcr from "./Pages/OCR/AadhaarOcr";
import OtherCollections from "./Pages/OtherCollections";
import QuickTools from "./Pages/QuickTools";
import Settings from "./Pages/Settings";
import AgentTrackerTable from "./Pages/Tables/AgentTrackerTable";
import CashReceiptTable from "./Pages/Tables/CashReceiptTable";
import CollectionTableV2 from "./Pages/Tables/CollectionTableV2";
import { EvProductsTable } from "./Pages/Tables/EvProductsTable";
import { LoanApplicationsTable } from "./Pages/Tables/LoanApplicationsTable";
import { PartnersTable } from "./Pages/Tables/PartnersTable";
import TransationsTableV2 from "./Pages/Tables/TransactionsTable-v2";
import { UserTable } from "./Pages/Tables/UserTable";
import ViewCollections from "./Pages/Tables/ViewCollection/ViewCollections";
import TestScreen from "./Pages/TestScreen";
import UpdateAnchor from "./Pages/UpdateAnchor/UpdateAnchor";
import UpdateStageV2 from "./Pages/UpdateStage/UpdateStageV2";
import HomeScreen2 from "./Pages/V2/HomeScreen";
import SelectApplication from "./Pages/ViewApplication/SelectApplication";
import ViewApplication from "./Pages/ViewApplication/ViewApplication";
import GPSDashboard from "./Pages/gps/Dashboard";
import UpdateApplication from "./Pages/updateStep/UpdateApplication";
import { getTokenFn, onMessageListener } from "./config/firebaseInit";
import PendencyTracker from "./Pages/PendencyTracker/PendencyTracker";
import MapViewSatellite from "./Pages/Tables/MapViewSatellite";
import SocketContextProvider from "./Context/SocketContext";
import InternetConnectionStatus from "./InternetConnectionStatus";
import { logout } from "./api";

function App() {
  const { tokenState, notificationState, notiCountState } =
    useContext(GlobalContext);
  const [tokenData, setTokenData] = tokenState;
  const [notificationData, setNotification] = notificationState;
  const [notiCount, setNotiCount] = notiCountState;
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const INACTIVITY_TIMEOUT = 480000;
  let inactivityTimer = null;

  useEffect(() => {
    getTokenFn(setTokenData);
  }, []);

  useEffect(() => {
    const resetTimer = () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        if (email.startsWith("offshoot")) {
          logoutUser();
        }
      }, INACTIVITY_TIMEOUT);
    };

    const logoutUser = async () => {
      try {
        setIsLoggingOut(true);
        await logout({ token: tokenData.token });
        localStorage.removeItem('email');
        window.location.reload();
      } catch (error) {
        setError('Error logging out');
        console.error(error);
      } finally {
        setIsLoggingOut(false);
      }
    };

    const handleTabClose = (event) => {
      if (email.startsWith("offshoot")) {
        logoutUser();
      }
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("beforeunload", handleTabClose);

    resetTimer();

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("beforeunload", handleTabClose);
      if (inactivityTimer) clearTimeout(inactivityTimer);
    };
  }, [email, isLoggingOut]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        open: true,
        title: payload?.notification?.title || "",
        body: payload?.notification?.body || "",
        image: payload?.notification?.image || "",
      });
      setNotiCount(notiCount + 1);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
    <InternetConnectionStatus/>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route exact path="/login" element={<LoginV2 setEmail={setEmail}/>} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route
              exact
              path="/dashboard"
              element={
                <HomeScreen2 children={<Dashboard />} active={"dashboard"} />
              }
            />
            <Route
              exact
              path="/tools"
              element={
                <HomeScreen2 children={<QuickTools />} active={"tools"} />
              }
            />
            <Route
              exact
              path="/loan-applications"
              element={
                <HomeScreen2
                  children={<LoanApplicationsTable />}
                  active={"loan-applications"}
                />
              }
            />
            <Route
              exact
              path="/map-view-satellite"
              element={
                <HomeScreen2
                  children={<MapViewSatellite />}
                  active={"map-view-satellite"}
                />
              }
            />
            <Route
              exact
              path="/collections"
              element={
                <HomeScreen2
                  children={<CollectionTableV2 />}
                  active={"collections-v2"}
                />
              }
            />
            <Route
              exact
              path="/other-collections"
              element={
                <HomeScreen2
                  children={<OtherCollections />}
                  active={"other-collections"}
                />
              }
            />

            <Route
              exact
              path="/cash-receipt-data"
              element={
                <HomeScreen2
                  children={<CashReceiptTable />}
                  // active={"other-collections"}
                />
              }
            />

            <Route
              exact
              path="/collections-v2"
              element={
                <HomeScreen2
                  children={<CollectionTableV2 />}
                  active={"collections-v2"}
                />
              }
            />

            <Route
              exact
              path="/collections-v2/:application_id"
              element={
                <HomeScreen2
                  children={<CollectionTableV2 />}
                  active={"collections-v2"}
                />
              }
            />

            <Route
              exact
              path="/transactions"
              element={
                <HomeScreen2
                  children={<TransationsTableV2 />}
                  active={"transactions-v2"}
                />
              }
            />

            <Route
              exact
              path="/transactions-v2"
              element={
                <HomeScreen2
                  children={<TransationsTableV2 />}
                  active={"transactions-v2"}
                />
              }
            />
            <Route
              exact
              path="/partners"
              element={
                <HomeScreen2 children={<PartnersTable />} active={"partners"} />
              }
            />
            <Route
              exact
              path="/users"
              element={
                <HomeScreen2 children={<UserTable />} active={"users"} />
              }
            />

            <Route
              exact
              path="/evproducts"
              element={
                <HomeScreen2 children={<EvProductsTable />} active={""} />
              }
            />

            <Route
              exact
              path="/settings"
              element={
                <HomeScreen2 children={<Settings />} active={"settings"} />
              }
            />

            <Route
              exact
              path="/"
              element={
                <HomeScreen2 children={<Dashboard />} active={"dashboard"} />
              }
            />
            <Route
              exact
              path="/generate-schedule"
              element={<HomeScreen2 children={<Scheduler />} isClosed={true} />}
            />
            <Route
              exact
              path="/create-app"
              element={
                <HomeScreen2 children={<CreateApplication />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-app"
              element={
                <HomeScreen2 children={<UpdateApplication />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-app/:app_id"
              element={
                <HomeScreen2 children={<UpdateApplication />} isClosed={true} />
              }
            />
            <Route exact path="/aadhaar-ocr" element={<AadhaarOcr />} />
            <Route
              exact
              path="/view-application"
              element={
                <HomeScreen2 children={<SelectApplication />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/view-application/:app_id"
              element={<ViewApplication />}
            />
            <Route
              exact
              path="/view-application/:app_id/:stage"
              element={<ViewApplication />}
            />
            <Route exact path="/b2c-validations" element={<B2CValidations />} />
            <Route
              exact
              path="/update-anchor"
              element={
                <HomeScreen2 children={<UpdateAnchor />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-anchor/:anchor_id"
              element={
                <HomeScreen2 children={<UpdateAnchor />} isClosed={true} />
              }
            />
            {/* <Route
                exact
                path="/update-stage"
                element={
                  <HomeScreen2 children={<UpdateStage />} isClosed={true} />
                }
              />
              <Route
                exact
                path="/update-stage/:app_id"
                element={
                  <HomeScreen2 children={<UpdateStage />} isClosed={true} />
                }
              /> */}

            <Route
              exact
              path="/update-stage-v2/:app_id"
              element={
                <HomeScreen2 children={<UpdateStageV2 />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/update-stage-v2"
              element={
                <HomeScreen2 children={<UpdateStageV2 />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/shared-dealer-data"
              element={<HomeScreen2 children={<CsvJson />} isClosed={true} />}
            />

            <Route
              exact
              path="/add-product"
              element={
                <HomeScreen2 children={<AddProduct />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/create-user"
              element={
                <HomeScreen2 children={<CreateUser />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/create-agent"
              element={
                <HomeScreen2 children={<CreateAgent />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-agent/:agentId"
              element={
                <HomeScreen2
                  children={<CreateAgent isUpdate />}
                  isClosed={true}
                />
              }
            />
            <Route
              exact
              path="/create-anchor"
              element={
                <HomeScreen2 children={<CreateAnchor />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/create-anchor-v2"
              element={
                <HomeScreen2 children={<CreateAnchorV2 />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-anchor-v2/:anchorId"
              element={
                <HomeScreen2 children={<UpdateAnchor2 />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/create-oem"
              element={
                <HomeScreen2 children={<OemDetailsV2 />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/update-oem/:oemId"
              element={
                <HomeScreen2
                  children={<OemDetailsV2 isUpdate />}
                  isClosed={true}
                />
              }
            />

            <Route
              exact
              path="/upload-documents"
              element={
                <HomeScreen2 children={<DocumentUploader />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/upload-documents/:app_id"
              element={
                <HomeScreen2 children={<DocumentUploader />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/view-documents"
              element={
                <HomeScreen2 children={<ViewDocuments />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/view-documents/:app_id"
              element={
                <HomeScreen2 children={<ViewDocuments />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-collection/"
              element={
                <HomeScreen2 children={<AddTransacation />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-collection/:app_id"
              element={
                <HomeScreen2 children={<AddTransacation />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/update-collection-v2/"
              element={
                <HomeScreen2 children={<AddTransacation />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/update-collection-v2/:app_id"
              element={
                <HomeScreen2 children={<AddTransacation />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/dealer-profile/:anchorId"
              element={
                <HomeScreen2 children={<DealerProfile />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/gps-overview"
              element={
                <HomeScreen2
                  children={<GPSOverview />}
                  active={"gps-overview"}
                />
              }
            />
            <Route exact path="/table" element={<ViewCollections />} />

            <Route
              exact
              path="/dealer-score"
              element={
                <HomeScreen2 children={<DealerScore />} isClosed={true} />
              }
            />
            <Route
              exact
              path="/kyc"
              element={<HomeScreen2 children={<SelectKYC />} isClosed={true} />}
            />
            <Route
              exact
              path="/kyc/:kycId"
              element={
                <HomeScreen2 children={<KYCDashboard />} isClosed={true} />
              }
            />

            <Route
              exact
              path="/drf-sheet"
              element={<HomeScreen2 children={<DRFSheet />} isClosed={true} />}
            />

            <Route
              exact
              path="/notifications"
              element={<HomeScreen2 children={<Notifications />} />}
            />

            <Route
              exact
              path="/agent-tracker"
              element={
                <HomeScreen2
                  children={<AgentTrackerTable />}
                  active={"agent-tracker"}
                />
              }
            />

            <Route
              exact
              path="/gps"
              element={
                <HomeScreen2 children={<GPSDashboard />} active={"gps"} />
              }
            />

            <Route
              exact
              path="/pendency-tracker"
              element={<HomeScreen2 active={"pendancy-tracker"} children={ 
                <SocketContextProvider><PendencyTracker/></SocketContextProvider>} />}
            />

            <Route exact path="/test" element={<TestScreen />} />
          </Route>
        </Routes>
        <NotificationPopup />
      </Router>
    </>
  );
}

export default App;