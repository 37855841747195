import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { assignNBFC, changeNBFC } from "../../api";
import { toast } from "react-toastify";
import "./AssignNBFC.css";
import { useState } from "react";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { GlobalContext } from "../../Context/GlobalContext";
import { useContext } from "react";
import { f } from "../../utility/Formater";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment/moment";
import { isAmountValid } from "../../utility/validations";
import { UserContext } from "../../Context/UserContext";
import { InfoOutlined, SpaTwoTone } from "@mui/icons-material";
import { useMediaQuery } from '@mui/material';

const editInitialData = {
  loan_amount: "",
  interest_rate: "",
  interest: "",
  tenure: "",
  processing_fee: "",
  emi: "",
  edi: "",
  processing_charge_rate: "",
  subvention_amount: "",
  subvention_rate: "",
  subvention_mode: "",
  hold_back_amount: "",
  hold_back_mode: "",
  oem_id: "",
  gps_charges: "",
};
const initInfoObj = {
  disbursal_amount: "",
};

const AssignNBFC = ({
  openNBFC,
  setOpenNBFC,
  setNbfcAssignData,
  nbfcAssignData,
  nbfcList,
  Transition,
  refresh,
  application_id,
}) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [editData, setEditData] = useState({
    ...editInitialData,
    hold_back_amount: updateDetails?.hold_back_amount,
  });
  const [infoObj, setInfoObj] = useState(initInfoObj);
  const [demoNBFCList, setDemoList] = useState([]);
  const { user } = useContext(UserContext);

  const [edit, setEdit] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [oemId, setOemId] = useState(null);
  // const [holdBackAmount, setHoldBackAmount] = useState(updateDetails?.anchor_hold_back_amount);

  const isValid = async () => {
    let valid = true;
    if (edit) {
      let errors = {
        loan_amount: isAmountValid(editData?.loan_amount),
        interest_rate: isAmountValid(editData?.interest_rate),
        processing_fee: isAmountValid(editData?.processing_fee, true),
        tenure_value: isAmountValid(editData?.tenure_value),
        hold_back_amount: isAmountValid(editData?.hold_back_amount, true),
        subvention_amount: isAmountValid(editData?.subvention_amount, true),
        processing_charge_rate: isAmountValid(
          editData?.processing_charge_rate,
          true
        ),
      };

      if (editData.loan_amount > updateDetails?.vehicle_price_ex) {
        errors.loan_amount =
          "Cannot be greater than Ex-showroom price (" +
          f(updateDetails?.vehicle_price_ex, "cur") +
          ")";
      }

      if (Number(editData.loan_amount) < 50000) {
        errors.loan_amount = "Cannot be lessthan 50,000";
      }

      if (
        Number(editData.interest_rate) > 100 ||
        Number(editData.interest_rate) < 12
      ) {
        errors.interest_rate = "Cannot be greater than 100 or less than 12";
      }

      if (
        Number(editData.processing_fee) >
        Number(editData.loan_amount) * 0.1
      ) {
        errors.processing_fee = "Cannot be greater than 10% of loan amount";
      }

      if (
        Number(editData.tenure_value) > 24 ||
        Number(editData.tenure_value) < 12
      ) {
        errors.tenure_value = "Cannot be greater than 24 or less than 12";
      }

      if (Number(editData.subvention_amount) > Number(editData?.loan_amount)) {
        errors.subvention_amount = "Cannot be greater than loan amount";
      }
      if (Number(editData.hold_back_amount) > Number(editData?.loan_amount)) {
        errors.hold_back_amount = "Cannot be greater than loan amount";
      }

      setFieldErrors(errors);

      for (let i in errors) {
        if (errors[i].length > 0) {
          valid = false;
        }
      }

      return valid;
    } else {
      return true;
    }
  };

  const handleAssignNBFC = async () => {
    if ([null, "", undefined].includes(nbfcAssignData?.nbfc_id)) {
      toast.error("Please select NBFC");
      return;
    }

    if (!(await isValid())) {
      toast.error("Please check all the fields");
      return;
    }

    try {
      let payload = {
        stage: "NBFC_SELECTION",
        sub_status: "NBFC_ASSIGNED",
        status: "COMPLETED",
        remarks: "nbfc assigned manually",
        data: {
          nbfc_id: nbfcAssignData?.nbfc_id,
        },
        isEdit: edit,
      };
      let loan_details = { hold_back_mode: "N/A" };
      if (edit && !!editData?.hold_back_amount) {
        loan_details = {
          ...loan_details,
          hold_back_mode: editData?.hold_back_mode,
        };
      }

      if (edit && !!editData?.subvention_amount) {
        loan_details = {
          ...loan_details,
          subvention_mode: editData?.subvention_mode,
        };
      }

      if (!edit && !!updateDetails?.hold_back_amount) {
        loan_details = {
          ...loan_details,
          hold_back_mode: editData?.hold_back_mode,
        };
      }

      if (!edit && !!updateDetails?.subvention_amount) {
        loan_details = {
          ...loan_details,
          subvention_mode: editData?.subvention_mode,
        };
      }

      payload = {
        ...payload,
        loan_details: {
          ...(payload?.loan_details || {}),
          ...loan_details,
          hold_back_amount: editData?.hold_back_amount,
          subvention_amount: updateDetails?.subvention_amount,
        },
      };

      if (edit) {
        payload = {
          ...payload,
          loan_details: {
            ...(payload?.loan_details || {}),
            loan_amount: Number(editData?.loan_amount),
            interest_rate: Number(editData?.interest_rate),
            interest_amount: Number(editData?.interest),
            processing_charge: Number(editData?.processing_fee),
            hold_back_amount: Number(editData?.hold_back_amount),
            subvention_amount: Number(editData?.subvention_amount),
            tenure_type: "months",
            tenure_value: Number(editData?.tenure_value),
            processing_charge_rate: Number(editData?.processing_charge_rate),
            down_payment:
              Number(updateDetails?.vehicle_price_onroad) -
              Number(editData?.loan_amount),
            oem_id: editData?.oem_id,
            gps_charges: editData?.gps_charges,
          },
        };
      }
      setNbfcAssignData({ ...nbfcAssignData, isLoading: true });
      let { data } = await assignNBFC(payload, application_id);
      if (data?.status) {
        toast.success("NBFC Assigned Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "NBFC Assigned Failed");
    }

    setOpenNBFC({ open: false, type: "" });
    setNbfcAssignData({ ...nbfcAssignData, isLoading: false });
    refresh();
  };

  const handleChangeNBFC = async () => {
    try {
      if (nbfcAssignData?.nbfc_id === updateDetails?.nbfc_id) {
        if (!edit) {
          toast.info("NBFC selected is same as previous one");
          return false;
        }
      }

      if ([null, "", undefined].includes(nbfcAssignData?.nbfc_id)) {
        toast.error("Please select NBFC");
        return;
      }

      if (!(await isValid())) {
        return;
      }

      let payload = {
        nbfc_id: nbfcAssignData?.nbfc_id,
        isEdit: edit,
      };

      let loan_details = { hold_back_mode: "N/A" };
      if (edit && !!editData?.hold_back_amount) {
        loan_details = {
          ...loan_details,
          hold_back_mode: editData?.hold_back_mode,
        };
      }

      if (edit && !!editData?.subvention_amount) {
        loan_details = {
          ...loan_details,
          subvention_mode: editData?.subvention_mode,
        };
      }

      if (!edit && !!updateDetails?.hold_back_amount) {
        loan_details = {
          ...loan_details,
          hold_back_mode: editData?.hold_back_mode,
        };
      }

      if (!edit && !!updateDetails?.subvention_amount) {
        loan_details = {
          ...loan_details,
          subvention_mode: editData?.subvention_mode,
        };
      }

      payload = {
        ...payload,
        loan_details: {
          ...(payload?.loan_details || {}),
          ...loan_details,
        },
      };

      if (edit) {
        payload = {
          ...payload,
          loan_details: {
            loan_amount: Number(editData?.loan_amount),
            interest_rate: Number(editData?.interest_rate),
            interest_amount: Number(editData?.interest),
            processing_charge: Number(editData?.processing_fee),
            tenure_type: "months",
            tenure_value: Number(editData?.tenure_value),
            hold_back_amount: Number(editData?.hold_back_amount),
            subvention_amount: Number(editData?.subvention_amount),
            processing_charge_rate: Number(editData?.processing_charge_rate),
            down_payment:
              Number(updateDetails?.vehicle_price_onroad) -
              Number(editData?.loan_amount),
            oem_id: editData?.oem_id,
            gps_charges: editData?.gps_charges,
          },
        };
        if (Number(editData?.subvention_amount)) {
          payload = {
            ...payload,
            loan_details: {
              ...(payload?.loan_details || {}),
              subvention_mode: editData?.subvention_mode,
            },
          };
        }
        if (Number(editData?.hold_back_amount)) {
          payload = {
            ...payload,
            loan_details: {
              ...(payload?.loan_details || {}),
              hold_back_mode: editData?.hold_back_mode,
            },
          };
        }
      }
      payload.loan_details.hold_back_amount = editData?.hold_back_amount;
      setNbfcAssignData({ ...nbfcAssignData, isLoading: true });
      let { data } = await changeNBFC(payload, application_id);

      if (data?.status) {
        toast.success("NBFC changed Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "NBFC Assigned Failed");
    }

    setOpenNBFC({ open: false, type: "" });
    setNbfcAssignData({ ...nbfcAssignData, isLoading: false });
    refresh();
  };

  const calculateInstallment = (loan_amount, interest_rate, tenure) => {
    let startDate = moment(new Date());
    let endDate = moment(new Date()).add(Number(tenure), "months");

    let ediDays = moment(endDate).diff(moment(startDate), "days");

    let numerator =
      Number(loan_amount) +
      Number(loan_amount) *
        (Number(interest_rate) / 100) *
        (Number(tenure) / 12);

    // console.log(numerator);

    let emi = (numerator / Number(tenure)).toFixed(2);
    let edi = (numerator / ediDays).toFixed(2);
    return { emi, edi };
  };

  const handleEditLoanAmount = (e) => {
    if (e.target.value?.length > 7) return false;
    setFieldErrors({ ...fieldErrors, loan_amount: "" });

    let oemData = null;
    for (const oem of updateDetails?.oem_details) {
      if (oem.oem_id === oemId) {
        oemData = oem;
      }
    }
    let fixed = null;
    let pfFixed = null;
    if (oemData) {
      if (oemData?.subvention?.amount) {
        fixed = "amount";
      } else if (oemData?.subvention?.percent) {
        fixed = "perc";
      }

      if (oemData?.processing_fees) {
        pfFixed = "amount";
      } else if (oemData?.processing_fees_percent) {
        pfFixed = "perc";
      }
    }

    let data = {
      loan_amount: e.target.value,
    };

    if (fixed === "amount") {
      data = {
        ...data,
        subvention_rate:
          editData?.subvention_amount && e.target.value
            ? (
                (editData?.subvention_amount / Number(e.target.value)) *
                100
              )?.toFixed(2)
            : 0,
      };
    } else {
      data = {
        ...data,
        subvention_amount: (
          (Number(editData?.subvention_rate || 0) * Number(e.target.value)) /
          100
        ).toFixed(2),
      };
    }

    if (pfFixed === "amount") {
      data = {
        ...data,
        processing_charge_rate:
          editData?.processing_fee && e.target.value
            ? (
                (Number(editData.processing_fee) * 100) /
                Number(e.target.value)
              ).toFixed(2)
            : 0,
      };
    } else {
      data = {
        ...data,
        processing_fee:
          editData?.processing_charge_rate && e.target.value
            ? (
                (Number(editData?.processing_charge_rate) *
                  Number(e.target.value)) /
                100
              )?.toFixed(2)
            : 0,
      };
    }

    setEditData({
      ...editData,
      ...data,
    });
  };

  function changeMode(nbfcId, mode, value) {
    if (nbfcId === updateDetails?.nbfc_id) {
      setEditData((prev) => ({
        ...prev,
        [mode]: value
          ? value
          : ["FI00005", "MF00006"].includes(nbfcId)
          ? "UPFRONT_COLLECTED_BY_EMBIFI"
          : "UPFRONT_DEDUCTED",
      }));
    } else {
      setEditData((prev) => ({
        ...prev,
        [mode]: ["FI00005", "MF00006"].includes(nbfcId)
          ? "UPFRONT_COLLECTED_BY_EMBIFI"
          : "UPFRONT_DEDUCTED",
      }));
    }
  }

  function changeHoldBackAmount(oemId) {
    const holdBackAmount = (() => {
      if (updateDetails?.anchor_hold_back_type === "DEALER") {
        const hold_back_amount = updateDetails?.anchor_hold_back_amount;
        if (hold_back_amount?.total == hold_back_amount?.utilized) {
          return 0;
        } else {
          return hold_back_amount?.per_file;
        }
        // const nbfcLimit = updateDetails?.anchor_hold_back_nbfc_limits?.find(
        //   (obj) => obj?.nbfc_id == nbfcId
        // );
        // if (nbfcLimit) {
        //   setFieldErrors({
        //     ...fieldErrors,
        //     hold_back_amount: "",
        //   });
        //   return nbfcLimit?.per_file;
        // } else {
        //   setFieldErrors({
        //     ...fieldErrors,
        //     hold_back_amount: "Nbfc limit is not listed for this dealer.",
        //   });
        // }
      } else if (updateDetails?.anchor_hold_back_type === "OEM") {
        const oem = updateDetails?.oem_details?.find(
          (obj) => obj?.oem_id === oemId
        );
        const hold_back_amount = oem?.hold_back_amount;
        if (hold_back_amount?.total == hold_back_amount?.utilized) {
          return 0;
        } else {
          return hold_back_amount?.per_file;
        }
        // const nbfcLimit = oem?.hold_back_nbfc_limits?.find(
        //   (obj) => obj?.nbfc_id == nbfcId
        // );
        // if (nbfcLimit) {
        //   setFieldErrors({
        //     ...fieldErrors,
        //     hold_back_amount: "",
        //   });
        //   return nbfcLimit?.per_file;
        // } else {
        //   setFieldErrors({
        //     ...fieldErrors,
        //     hold_back_amount: "Nbfc limit is not listed for this dealer.",
        //   });
        // }
      } else {
        return 0;
      }
    })();
    setEditData((prev) => ({
      ...prev,
      hold_back_amount: holdBackAmount,
    }));
    setUpdateDetails((prev)=>({
      ...prev,
      hold_back_amount: holdBackAmount,
    }))
  }

  function isValidHoldBack(amount) {
    amount = +amount;
    return !(
      [undefined, null, "", , "NA", "na", "N/A", "n/a"].includes(amount) ||
      amount <= 0
    );
  }

  function handleInitCalc(stateName) {
    let info = { ...initInfoObj };
    let disbursal_amount = 0;
    setUpdateDetails((prev) => {
      info.disbursal_amount += `Disbursed Amount = `;
      if (!isNaN(stateName?.[`loan_amount`])) {
        // ! can be modefied
        disbursal_amount = stateName?.loan_amount;
        info.disbursal_amount += `Loan Amount`;
        if (
          !isNaN(Number(stateName?.[edit ? `interest` : `interest_amount`])) && // ! can be modefied
          prev?.interest_collected === "UPFRONT_DEDUCTED"
        ) {
          disbursal_amount -=
            stateName?.[edit ? `interest` : `interest_amount`];
          info.disbursal_amount += `- Interest Collected`;
        }
        if (
          !isNaN(stateName?.[`processing_fee`]) ||
          (!isNaN(stateName?.[`processing_rate`]) && // ! can be modefied
            stateName?.pf_collected === "UPFRONT_DEDUCTED")
        ) {
          let processing_fee = 0;
          if (stateName?.[`processing_rate`]) {
            processing_fee = Number(
              (stateName?.[`processing_rate`] * stateName?.loan_amount) / 100
            )?.toFixed(2);
          } else processing_fee = stateName?.processing_fee;
          disbursal_amount -= stateName?.processing_fee;
          info.disbursal_amount += `- Processing Fee`;
        }

        if (
          stateName?.[`subvention_amount`] && // ! can be modefied
          !isNaN(stateName?.[`subvention_amount`]) &&
          stateName?.subvention_mode === "UPFRONT_DEDUCTED"
        ) {
          disbursal_amount -= stateName?.subvention_amount;
          info.disbursal_amount += `- Dealer Fee`;
        }
        if (
          stateName?.[`hold_back_amount`] && // ! can be modefied
          !isNaN(stateName?.[`hold_back_amount`]) &&
          stateName?.hold_back_mode === "UPFRONT_DEDUCTED"
        ) {
          disbursal_amount -= stateName?.[`hold_back_amount`];
          info.disbursal_amount += ` - Holdback Amount`;
        }
        if (!isNaN(stateName?.[`gps_charges`])) {
          disbursal_amount -= stateName?.[`gps_charges`];
          info.disbursal_amount += `- GPS charges`;
        }
        if (!isNaN(prev?.[`other_charges`])) {
          disbursal_amount -= prev?.[`other_charges`];
          info.disbursal_amount += `- Other Charges`;
        }
      }

      setEditData((prev) => ({
        ...prev,
        disbursal_amount,
      }));
      setInfoObj(info);
      return {
        ...prev,
      };
    });
  }

  useEffect(() => {
    if (!!editData?.loan_amount) handleInitCalc(editData);
  }, [
    editData?.loan_amount,
    editData?.hold_back_amount,
    editData?.hold_back_mode,
    editData?.subvention_mode,
    editData?.subvention_amount,
    editData?.interest,
    editData?.processing_fee,
  ]);

  useEffect(() => {
    if (edit) {
      let state = {
        ...editData,
        loan_amount: updateDetails?.loan_amount || 0,
        interest_rate: updateDetails?.interest_rate || 0,
        interest: updateDetails?.interest || 0,
        tenure_value: updateDetails?.tenure_value || 0,
        processing_fee: updateDetails?.processing_fee || 0,
        emi: updateDetails?.emi || 0,
        edi: updateDetails?.edi || 0,
        subvention_rate: updateDetails?.subvention_amount
          ? Number(
              (updateDetails?.subvention_amount / updateDetails?.loan_amount) *
                100
            ).toFixed(2)
          : 0,
        subvention_amount: updateDetails?.subvention_amount || 0,
        subvention_mode: updateDetails?.subvention_mode,
        // hold_back_amount: updateDetails?.hold_back_amount || 0,
        hold_back_mode: updateDetails?.hold_back_mode,
        processing_charge_rate: updateDetails?.processing_rate || 0,
        disbursal_amount: updateDetails?.disbursal_amount,
        oem_id: updateDetails?.oem_id,
        gps_charges: updateDetails?.gps_charges,
      };
      setEditData(state);
      setOemId(updateDetails?.oem_id);
    } else {
      setFieldErrors({});
      setEditData({
        ...editInitialData,
        hold_back_amount: updateDetails?.hold_back_amount,
      });
    }
    changeMode(
      nbfcAssignData?.nbfc_id,
      "subvention_mode",
      updateDetails?.subvention_mode
    );
    changeMode(
      nbfcAssignData?.nbfc_id,
      "hold_back_mode",
      updateDetails?.hold_back_mode
    );
    // changeHoldBackAmount(
    //   updateDetails?.oem_id
    // );
    handleInitCalc(updateDetails);
  }, [edit]);

  useEffect(() => {
    let val =
      (Number(editData?.loan_amount) *
        (Number(editData.interest_rate) / 100) *
        Number(editData?.tenure_value)) /
      12;

    let installment = calculateInstallment(
      editData?.loan_amount,
      editData?.interest_rate,
      editData?.tenure_value
    );
    setEditData({
      ...editData,
      interest: val,
      emi: Math.ceil(installment?.emi),
      edi: Math.ceil(installment?.edi),
    });
  }, [editData?.loan_amount, editData?.interest_rate, editData?.tenure_value]);

  useEffect(() => {
    if (openNBFC?.open) {
      let installment = calculateInstallment(
        updateDetails?.loan_amount,
        updateDetails?.interest_rate,
        updateDetails?.tenure_value
      );

      setUpdateDetails({
        ...updateDetails,
        emi: Math.ceil(installment?.emi),
        edi: Math.ceil(installment?.edi),
      });

      setNbfcAssignData({
        ...nbfcAssignData,
        nbfc_id: updateDetails?.nbfc_id || "",
      });
      // changeHoldBackAmount(
      //   editData?.oem_id ? editData?.oem_id : updateDetails?.oem_id
      // );
      setEditData({
        ...editData,
        hold_back_amount: updateDetails?.hold_back_amount,
      });
      handleInitCalc(updateDetails);
    } else {
      setEdit(false);
      setEditData({
        ...editInitialData,
        hold_back_amount: updateDetails?.hold_back_amount,
      });
    }
        
    changeMode(
      updateDetails?.nbfc_id,
      "subvention_mode",
      updateDetails?.subvention_mode
    );

    changeMode(
      updateDetails?.nbfc_id,
      "hold_back_mode",
      updateDetails?.hold_back_mode
    );
  }, [openNBFC?.open]);

  useEffect(()=>{
    console.log("editData => ",editData);
  },[editData])

  // useEffect(() => {
  //   const removeList = ["NY00002", "MF00006", "FI00005", "PL00003", "VA00001"];
  //   if (user?.isDemoUser) {
  //     let newList = nbfcList?.filter((val) => {
  //       return !removeList?.includes(val?._id);
  //     });

  //     setDemoList(newList);
  //   }
  // }, [nbfcList]);
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Dialog
      open={openNBFC?.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setOpenNBFC({ open: false, type: "" });
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {openNBFC?.type === "ASSIGN" ? "Assign NBFC" : "Change NBFC"}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: isMobile ? 300 : 400 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // label="NBFC"
            fullWidth
            value={nbfcAssignData?.nbfc_id}
            onChange={(e) => {
              setNbfcAssignData({
                ...nbfcAssignData,
                nbfc_id: e.target.value,
              });
              changeMode(e.target.value, "subvention_mode");
              changeMode(e.target.value, "hold_back_mode");
              changeHoldBackAmount(
                editData?.oem_id ? editData?.oem_id : updateDetails?.oem_id
              );
            }}
          >
            {nbfcList.map((item) => {
              return (
                <MenuItem
                  value={item?._id}
                >{`${item?._id} - ${item?.name}`}</MenuItem>
              );
            })}
          </Select>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 20,
              height: 40,
              width: "100%",
            }}
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              Loan Summary
            </span>
            {
              <Button
                className={"nbfc-dialog-edit-btn"}
                onClick={() => {
                  setEdit(!edit);
                }}
                size="small"
                sx={{
                  // margin:0,
                  // padding:0,
                  marginLeft: 1,
                  fontSize: "12px",
                }}
              >
                {edit ? (
                  <div>
                    <ClearIcon sx={{ fontSize: 15 }} /> Cancel
                  </div>
                ) : (
                  <div>
                    <EditIcon sx={{ fontSize: 14 }} /> Edit
                  </div>
                )}
              </Button>
            }
          </div>

          {edit && (
            <Grid container spacing={1} className="mb-3 mt-1">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={oemId}
                onChange={(e) => {
                  setOemId(e?.target?.value);
                }}
                disabled={!edit}
              >
                {updateDetails?.oem_details?.map((item) => {
                  return (
                    <MenuItem
                      value={item?.oem_id}
                      onClick={() => {
                        setEditData((prev) => ({
                          ...prev,
                          oem_id: item?.oem_id,
                          subvention_amount: item?.subvention?.amount
                            ? item?.subvention?.gst_type === "Exclusive"
                              ? Number(item?.subvention?.amount) +
                                (Number(item?.subvention?.amount) * 18) / 100
                              : item?.subvention?.amount
                            : item?.subvention?.percent
                            ? item?.subvention?.gst_type === "Exclusive"
                              ? (
                                  (Number(item?.subvention?.percent) *
                                    Number(editData?.loan_amount)) /
                                    100 +
                                  (((Number(item?.subvention?.percent) *
                                    Number(editData?.loan_amount)) /
                                    100) *
                                    18) /
                                    100
                                ).toFixed(2)
                              : (
                                  (Number(item?.subvention?.percent) *
                                    Number(editData?.loan_amount)) /
                                  100
                                ).toFixed(2)
                            : 0,
                          subvention_rate: item?.subvention?.percent
                            ? item?.subvention?.gst_type === "Exclusive"
                              ? (
                                  Number(item?.subvention?.percent) +
                                  (Number(item?.subvention?.percent) * 18) / 100
                                ).toFixed(2)
                              : item?.subvention?.percent
                            : item?.subvention?.amount
                            ? item?.subvention?.gst_type === "Exclusive"
                              ? (
                                  (Number(item?.subvention?.amount) /
                                    Number(editData?.loan_amount)) *
                                    100 +
                                  ((Number(item?.subvention?.amount) /
                                    Number(editData?.loan_amount)) *
                                    100 *
                                    18) /
                                    100
                                ).toFixed(2)
                              : (
                                  (Number(item?.subvention?.amount) /
                                    Number(editData?.loan_amount)) *
                                  100
                                ).toFixed(2)
                            : 0.0,
                          processing_fee: item?.processing_fees
                            ? item?.processing_fees
                            : item?.processing_fees_percent
                            ? (
                                (Number(item?.processing_fees_percent) *
                                  editData?.loan_amount) /
                                100
                              )?.toFixed(2)
                            : 0,
                          processing_charge_rate: item?.processing_fees_percent
                            ? item?.processing_fees_percent
                            : item?.processing_fees
                            ? (
                                (Number(item?.processing_fees) /
                                  editData?.loan_amount) *
                                100
                              )?.toFixed(2)
                            : 0,
                          interest_rate: item?.interest_rate || 0,
                          gps_charges: item?.gps_charges || 0,
                          hold_back_amount: (() => {
                            if (
                              updateDetails?.anchor_hold_back_type === "OEM"
                            ) {
                              const hold_back_amount = item?.hold_back_amount;
                              if (
                                hold_back_amount?.total ==
                                hold_back_amount?.utilized
                              ) {
                                return 0;
                              } else {
                                return hold_back_amount?.per_file;
                              }
                              // const nbfcLimit =
                              //   item?.hold_back_nbfc_limits?.find(
                              //     (obj) =>
                              //       obj?.nbfc_id ==
                              //       (nbfcAssignData?.nbfc_id
                              //         ? nbfcAssignData?.nbfc_id
                              //         : updateDetails?.nbfc_id)
                              //   );
                              // if (nbfcLimit) {
                              //   return nbfcLimit?.per_file;
                              // } else {
                              //   setFieldErrors({
                              //     ...fieldErrors,
                              //     hold_back_amount:
                              //       "Nbfc limit is not listed for this dealer.",
                              //   });
                              // }
                            } else {
                              return prev?.hold_back_amount;
                            }
                          })(),
                        }));
                      }}
                    >{`${item?.oem_name}`}</MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
          <Grid container spacing={1} className="mb-3 mt-1">
            <input type="hidden" name="email" />
            <Grid item xs={6} sm={6} className="label-text">
              <p className="mb-0">Loan Amount</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {edit ? (
                <TextField
                  fullWidth
                  size="small"
                  type="number" // Change the type to "number"
                  step="0.01" // Add the step attribute for decimal input
                  inputProps={{ maxLength: 7 }}
                  autoComplete="off"
                  value={
                    edit ? editData?.loan_amount : updateDetails?.loan_amount
                  }
                  error={fieldErrors?.loan_amount}
                  helperText={fieldErrors?.loan_amount}
                  onChange={handleEditLoanAmount}
                />
              ) : (
                <p>{f(updateDetails?.loan_amount, "cur")}</p>
              )}
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Annual Interest Rate</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {edit ? (
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  type="text"
                  inputProps={{ maxLength: 5 }}
                  error={fieldErrors?.interest_rate}
                  helperText={fieldErrors?.interest_rate}
                  value={
                    edit
                      ? editData?.interest_rate
                      : updateDetails?.interest_rate
                  }
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, interest_rate: "" });
                    setEditData({
                      ...editData,
                      interest_rate: e.target.value,
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.interest_rate, "rate")}</p>
              )}
            </Grid>

            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Annual Interest Amount</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              <p>
                {f(edit ? editData?.interest : updateDetails?.interest, "cur")}
              </p>
            </Grid>

            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Tenure</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 2 }}
                  error={fieldErrors?.tenure_value}
                  helperText={fieldErrors?.tenure_value}
                  value={
                    edit ? editData?.tenure_value : updateDetails?.tenure_value
                  }
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, tenure_value: "" });
                    setEditData({
                      ...editData,
                      tenure_value: e.target.value,
                    });
                  }}
                />
              ) : (
                <p>
                  {f(
                    updateDetails?.tenure_value &&
                      updateDetails?.tenure_type &&
                      `${updateDetails?.tenure_value} ${updateDetails?.tenure_type}`
                  )}
                </p>
              )}
            </Grid>
            {(edit
              ? !!editData?.subvention_amount
              : !!updateDetails?.subvention_amount) && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    height: 40,
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Dealer Fees
                  </p>
                  <div style={{ width: "60%" }}>
                    <FormControl fullWidth size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editData?.subvention_mode}
                        onChange={(e) => {
                          setFieldErrors({
                            ...fieldErrors,
                            subvention_mode: "",
                          });
                          setEditData({
                            ...editData,
                            subvention_mode: e.target.value,
                          });
                        }}
                        // disabled={!edit}
                        disabled={true}
                      >
                        <MenuItem value={"UPFRONT_COLLECTED_BY_EMBIFI"}>
                          Upfront Collected by Embifi
                        </MenuItem>
                        <MenuItem value={"UPFRONT_DEDUCTED"}>
                          Upfront Deducted
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </>
            )}
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Dealer Fees %</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  error={fieldErrors?.tenure_value}
                  helperText={fieldErrors?.tenure_value}
                  value={editData?.subvention_rate}
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, tenure_value: "" });
                    setEditData({
                      ...editData,
                      subvention_rate: e.target.value,
                      subvention_amount: (
                        (Number(e.target.value) *
                          Number(editData?.loan_amount)) /
                        100
                      ).toFixed(2),
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.subvention_rate)}</p>
              )} */}
              <p>{f(edit ? editData?.subvention_rate : updateDetails?.subvention_rate)}</p>
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Dealer Fees Amount</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  // inputProps={{ maxLength: 2 }}
                  error={fieldErrors?.subvention_amount}
                  helperText={fieldErrors?.subvention_amount}
                  value={
                    edit
                      ? editData?.subvention_amount
                      : updateDetails?.subvention_amount
                  }
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, subvention_amount: "" });
                    setEditData({
                      ...editData,
                      subvention_amount: e.target.value,
                      subvention_rate: (
                        (Number(e.target.value) /
                          Number(editData?.loan_amount)) *
                        100
                      ).toFixed(2),
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.subvention_amount)}</p>
              )} */}
              <p>{f(edit ? editData?.subvention_amount : updateDetails?.subvention_amount)}</p>
            </Grid>
            {isValidHoldBack(editData?.hold_back_amount) && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    height: 40,
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Hold Back
                  </p>
                  <div style={{ width: "60%" }}>
                    <FormControl fullWidth size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editData?.hold_back_mode}
                        onChange={(e) => {
                          setFieldErrors({
                            ...fieldErrors,
                            hold_back_mode: "",
                          });
                          setEditData({
                            ...editData,
                            hold_back_mode: e.target.value,
                          });
                        }}
                        // disabled={!edit}
                        disabled={true}
                      >
                        <MenuItem value={"UPFRONT_COLLECTED_BY_EMBIFI"}>
                          Upfront Collected by Embifi
                        </MenuItem>
                        <MenuItem value={"UPFRONT_DEDUCTED"}>
                          Upfront Deducted
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </>
            )}
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Hold Back</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  autoComplete="off"
                  // inputProps={{ maxLength: 2 }}
                  error={fieldErrors?.hold_back_amount}
                  helperText={fieldErrors?.hold_back_amount}
                  value={editData?.hold_back_amount}
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, hold_back_amount: "" });
                    setEditData({
                      ...editData,
                      hold_back_amount: e.target.value,
                    });
                  }}
                />
              ) : (
                <p>{f(editData?.hold_back_amount)}</p>
              )} */}
              <p>{f(editData?.hold_back_amount)}</p>
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">PF %</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  // inputProps={{ maxLength: 2 }}
                  error={fieldErrors?.processing_charge_rate}
                  helperText={fieldErrors?.processing_charge_rate}
                  value={editData?.processing_charge_rate}
                  onChange={(e) => {
                    setFieldErrors({
                      ...fieldErrors,
                      processing_charge_rate: "",
                    });
                    setEditData({
                      ...editData,
                      processing_charge_rate: e.target.value,
                      processing_fee: (
                        (Number(e.target.value) * editData?.loan_amount) /
                        100
                      )?.toFixed(2),
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.processing_rate)}</p>
              )} */}
              <p>{f(edit ? editData?.processing_charge_rate : updateDetails?.processing_rate)}</p>
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">PF Amount</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  // inputProps={{ maxLength: 2 }}
                  error={fieldErrors?.processing_fee}
                  helperText={fieldErrors?.processing_fee}
                  value={
                    edit
                      ? editData?.processing_fee
                      : updateDetails?.processing_fee
                  }
                  onChange={(e) => {
                    setFieldErrors({ ...fieldErrors, processing_fee: "" });
                    setEditData({
                      ...editData,
                      processing_fee: e.target.value,
                      processing_charge_rate: (
                        (Number(e.target.value) / editData?.loan_amount) *
                        100
                      )?.toFixed(2),
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.processing_fee)}</p>
              )} */}
              <p>{f(edit ? editData?.processing_fee : updateDetails?.processing_fee)}</p>
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">GPS Charges</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              {/* {edit ? (
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  autoComplete="off"
                  // inputProps={{ maxLength: 2 }}
                  // error={fieldErrors?.processing_fee}
                  // helperText={fieldErrors?.processing_fee}
                  value={
                    edit ? editData?.gps_charges : updateDetails?.gps_charges
                  }
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      gps_charges: e.target.value,
                    });
                  }}
                />
              ) : (
                <p>{f(updateDetails?.gps_charges)}</p>
              )} */}
              <p>{f(edit ? editData?.gps_charges : updateDetails?.gps_charges)}</p>
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">Disbursed Amount</p>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              className="nbfc-key-section d-flex align-items-center"
            >
              <p>{f(editData?.disbursal_amount)}</p>
              <InfoOutlined
                fontSize="small"
                titleAccess={infoObj?.disbursal_amount}
                sx={{ marginLeft: 1, cursor: "pointer" }}
              />
              {/* <div className="label">
                <p className="disbursed-amount">
                  Disbursed Amount = Loan amount - GPS Charges&nbsp;&nbsp;-
                  Other charges - PF Amount
                </p>
              </div> */}
            </Grid>
            <Grid item xs={6} sm={6} className="label-text">
              <p className="label-text mb-0">EMI Amount</p>
            </Grid>
            <Grid item xs={6} sm={6} className="nbfc-key-section">
              <p>{f(edit ? editData?.emi : updateDetails?.emi, "cur")}</p>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {nbfcAssignData?.isLoading ? (
          <>
            <Button disabled>
              <CircularProgress size={20} className="me-2" /> Loading{" "}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                setOpenNBFC({ open: false, type: "" });
              }}
            >
              Close
            </Button>
            <Button
              onClick={
                openNBFC?.type === "ASSIGN"
                  ? handleAssignNBFC
                  : handleChangeNBFC
              }
            >
              {openNBFC?.type === "ASSIGN" ? "Assign" : "Change"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AssignNBFC;
